import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EyeOutlined,
  TableOutlined,
} from '@ant-design/icons';
import {
  generateFamilyPathByAssetType,
  generatePathByAssetType,
} from 'business/common/services';
import {
  getCountriesKey,
  getCountryColumn,
  getCountryKey,
} from 'technical/countriesTranslation/constants';
import { TrademarkFamily } from 'business/resources/trademark/services/trademark.types';
import { Link } from 'react-router-dom';
import {
  FAMILIES_TABLE_IMAGE_CELL_WIDTH,
  FAMILIES_TABLE_IMAGE_STYLE,
} from 'ui/imageDisplay/constants';
import { ColumnGenerationData } from 'ui/table/types';
import { formatDate } from 'technical/date';
import { Button, Image, Tooltip } from 'antd';
import Loader from 'ui/loader';
import Spacer from 'ui/spacer';
import { TFunction } from 'i18next';

export const columns = {
  trademarkFamilies: (t: TFunction) => {
    return [
      {
        title: t('table.common.column.holder'),
        dataIndex: 'holder',
        sorter: true,
        key: 'holder',
      },
      {
        title: t('table.common.column.title', { context: 'design' }),
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        render: (_: any, record: any) => (
          <Link
            data-test-id="titleRedirect"
            className="titleRedirect"
            to={`/trademark/family/${record.id}`}
          >
            {record.title}
          </Link>
        ),
      },
      {
        title: 'image',
        dataIndex: 'image',
        key: 'image',
        sorter: false,
        render: (_: any, record: any) => {
          if (record.image === 'loading') {
            return <Loader />;
          }
          if (record.image) {
            return (
              <Image
                src={record.image}
                style={{ ...FAMILIES_TABLE_IMAGE_STYLE, objectFit: 'contain' }}
              />
            );
          }
        },
      },
      {
        title: t('table.common.column.countries'),
        sorter: true,
        dataIndex: 'countriesFr',
        key: 'countriesFr',
        render: (_: any, data: any) => {
          return (
            <Tooltip title={data[getCountriesKey()]}>
              {data[getCountriesKey()]}
            </Tooltip>
          );
        },
      },
      {
        title: t('table.common.column.firstDepositNumber'),
        dataIndex: 'firstDepositNumber',
        key: 'firstDepositNumber',
        sorter: true,
      },
      {
        title: t('table.common.column.firstDepositDate'),
        dataIndex: 'firstDepositDate',
        key: 'firstDepositDate',
        render: (_: any, data: any) => {
          if (data.firstDepositDate) {
            return formatDate(new Date(data.firstDepositDate));
          }
        },
        sorter: true,
      },
      {
        title: t('table.common.column.registrationNumber'),
        dataIndex: 'registrationNumber',
        key: 'registrationNumber',
        sorter: true,
      },
      {
        title: t('table.common.column.lastRegistrationDate'),
        dataIndex: 'lastRegistrationDate',
        key: 'lastRegistrationDate',
        render: (_: any, data: any) => {
          if (data.lastRegistrationDate) {
            return formatDate(new Date(data.lastRegistrationDate));
          }
        },
        sorter: true,
      },
      {
        title: t('table.common.column.clientRef'),
        dataIndex: 'clientRef',
        key: 'clientRef',
        sorter: true,
      },
      {
        title: t('table.common.column.bdlRef'),
        dataIndex: 'bdlRef',
        key: 'bdlRef',
        sorter: true,
      },
      {
        title: t('table.common.column.clientOfficer'),
        dataIndex: 'clientOfficer',
        key: 'clientOfficer',
        sorter: true,
      },
      {
        title: t('table.common.column.bdlOfficerName'),
        dataIndex: 'bdlOfficerName',
        key: 'bdlOfficerName',
        sorter: true,
      },
    ];
  },
  affidavits: ({
    onOpen,
    onDelete,
    onDownload,
    isBdlUser,
  }: {
    onOpen: (id: string) => Promise<void>;
    onDownload: (id: string, fileName: string) => Promise<void>;
    onDelete: (id: string) => void;
    isBdlUser: boolean;
  }) => {
    return [
      {
        title: 'Nom',
        dataIndex: 'title',
        sorter: false,
        key: 'title',
      },
      {
        title: 'Ajouté le',
        dataIndex: 'bucketPath',
        key: 'fullName',
        render: (_: any, data: any) => {
          return formatDate(new Date(data.bucketPath.createdAt));
        },
      },
      {
        title: 'Ajouté par',
        dataIndex: 'firstName',
        key: 'fullName',
        render: (_: any, data: any) => {
          return data.userFirstName + ' ' + data.userLastName;
        },
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: (_: any, data: any) => {
          return (
            <Spacer size="xxsmall">
              <Button
                type="primary"
                size="middle"
                icon={<EyeOutlined />}
                onClick={() => {
                  onOpen(data.bucketPathId);
                }}
              />
              <Button
                type="primary"
                size="middle"
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  onDownload(data.bucketPathId, data.title);
                }}
              />
              {!isBdlUser ? (
                <Button
                  type="primary"
                  size="middle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    onDelete(data.bucketPathId);
                  }}
                />
              ) : null}
            </Spacer>
          );
        },
      },
    ];
  },
};

export const trademarkFamilyColumns: ColumnGenerationData<TrademarkFamily> = [
  {
    ...getCountryColumn(),
    alwaysVisible: true,
    ellipsis: true,
    render: (_, trademark: TrademarkFamily, index) => (
      <Link
        className="titleRedirect"
        data-test-id={`titleRedirect-${index}`}
        to={generatePathByAssetType('trademark', trademark.id)}
      >
        {trademark[getCountryKey()]}
      </Link>
    ),
  },
  {
    key: 'classes',
    render: (classes: { classCode: string; classDetail: string }[]) => {
      return classes.map((c) => Number(c.classCode)).join('-');
    },
  },
  { key: 'depositNumber', ellipsis: true },
  { key: 'depositDate', ellipsis: true },
  { key: 'registrationNumber', ellipsis: true },
  {
    key: 'lastRegistrationDate',
    ellipsis: true,
    render: (_, data: TrademarkFamily) => {
      if (data.firstRegistrationDate) {
        return formatDate(new Date(data.firstRegistrationDate));
      }
      if (data.lastRegistrationDate) {
        return formatDate(new Date(data.lastRegistrationDate));
      }
      return null;
    },
  },
  {
    key: 'nextRenewDate',
    ellipsis: true,
    render: (_, data: TrademarkFamily) => {
      if (data.nextRenewDate) {
        return formatDate(new Date(data.nextRenewDate));
      }
    },
  },
  { key: 'grantNumber', ellipsis: true },
  {
    key: 'grantDate',
    ellipsis: true,
    render: (_: any, record: any) => {
      if (record.countryCode === 'US' && record.registrationDateUS) {
        return formatDate(new Date(record.registrationDateUS));
      }
      if (record.countryCode === 'JP' && record.registrationDateJP) {
        return formatDate(new Date(record.registrationDateJP));
      }
      if (record.countryCode === 'MX' && record.registrationDateMX) {
        return formatDate(new Date(record.registrationDateMX));
      }
      if (record.grantDate) {
        return formatDate(new Date(record.grantDate));
      }
    },
  },
  { key: 'bdlRef', ellipsis: true },
  { key: 'misRef', ellipsis: true },
  { key: 'clientRef', ellipsis: true },
  { key: 'clientRef2', ellipsis: true },
  { key: 'status' },
];

export const advancedSearchTrademarkColumns: ColumnGenerationData<TrademarkFamily> =
  [
    {
      key: 'holder',
    },
    {
      key: 'effectiveHolder',
      ellipsis: true,
    },
    {
      key: 'title',
      context: 'trademark',
      render: (_, { id, title }: TrademarkFamily, index) => (
        <Link
          className="titleRedirect"
          data-test-id={`titleRedirect-${index}`}
          to={generatePathByAssetType('trademark', id)}
        >
          {title}
        </Link>
      ),
    },
    {
      key: 'image',
      translationKey: 'image',
      sorter: false,
      width: FAMILIES_TABLE_IMAGE_CELL_WIDTH,
      render: (_: any, { image }) => {
        if (image === 'loading') {
          return <Loader />;
        }
        if (image) {
          return (
            <Image src={image} style={{ ...FAMILIES_TABLE_IMAGE_STYLE }} />
          );
        }
      },
    },
    {
      key: 'classes',
      render: (classes: { classCode: string; classDetail: string }[]) => {
        return classes.map((c) => Number(c.classCode)).join('-');
      },
    },
    {
      ...getCountryColumn(),
      alwaysVisible: true,
    },
    { key: 'depositNumber', ellipsis: true },
    { key: 'depositDate', ellipsis: true },
    { key: 'registrationNumber', checked: false, ellipsis: true },
    { key: 'firstRegistrationDate', checked: false, ellipsis: true },
    { key: 'lastRegistrationDate', checked: false, ellipsis: true },
    {
      key: 'nextRegistrationDate',
      ellipsis: true,
      render: (_, data: TrademarkFamily) => {
        if (data.nextRegistrationDate) {
          return formatDate(new Date(data.nextRegistrationDate));
        }
      },
    },
    { key: 'nextAction' },
    { key: 'grantNumber', ellipsis: true },
    {
      key: 'grantDate',
      ellipsis: true,
      render: (_: any, record: any) => {
        if (record.countryCode === 'US' && record.registrationDateUS) {
          return formatDate(new Date(record.registrationDateUS));
        }
        if (record.countryCode === 'JP' && record.registrationDateJP) {
          return formatDate(new Date(record.registrationDateJP));
        }
        if (record.countryCode === 'MX' && record.registrationDateMX) {
          return formatDate(new Date(record.registrationDateMX));
        }
        if (record.grantDate) {
          return formatDate(new Date(record.grantDate));
        }
      },
    },
    { key: 'bdlRef' },
    { key: 'misRef', checked: false },
    { key: 'clientRef' },
    { key: 'clientRef2' },
    {
      key: 'bdlOfficer',
    },
    {
      key: 'clientOfficer',
    },
    { key: 'status', ellipsis: true },

    {
      key: 'familyId',
      translationKey: 'family',
      render: (_, { familyId }: TrademarkFamily) => (
        <Link to={generateFamilyPathByAssetType('trademark', familyId)}>
          <TableOutlined />
        </Link>
      ),
    },
  ];
